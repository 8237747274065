/* NOTE: Spacing can be added to text in JSX with:
 * *&nbsp; for 1 space
 * &ensp; for 2 spaces
 * &emsp; for 4 spaces
*/
@media screen and (max-width: 600px) {
  .mainNavbar {
    display: none !important;
  }

  .logoMobile {
    height: auto;
    width: 280px;
  }

  .sideInfo {
    display: none !important;
  }

  .cardTitle {
    font-size: 36px !important;
  }

  .mainBody {
    font-size: 24px !important;
    margin: 0px !important;
  }

  .mainCards {
    margin: 5px !important;
  }

  .aboutDiv {
    margin: 0 15px !important;
  }

  .slideshow {
    height: 250px;
  }

  h1 {
    font-size: 36px !important;
  }

  .productsDiv {
    display: block !important;
    margin: 0;
  }

  .productCards {
    margin: 20px 0 !important;
  }

  .productBtnsDiv {
    display: block !important;
    text-align: center;
  }

  .operatorPic {
    padding: 0;
    height: auto;
  }

  .servicesCards {
    padding: 30px 10px !important;
  }

  .servicesDiv {
    margin: 20px 20px 0 20px !important;
  }

  .video {
    width: 400px;
    height: 225px;
  }

  .Modal {
    top: 80px !important;
    left: 10px !important;
    right: 10px !important;
    bottom: 20px !important;
  }

  .linkBtn {
    margin-bottom: 20px !important;
  }
}

@media screen and (min-width: 601px) {
  .mobileNav {
    display: none !important;
  }

  .logoLg {
    height: 70px;
  }

  .slideshow {
    height: 650px;
  }

  .operatorPic {
    padding: 0 100px;
    height: 350px;
  }

  .servicesCards {
    flex-direction: row;
  }

  .servicesCards img {
    width: 40%;
    padding-right: 40px;
  }

  .video {
    width: 1200px;
    height: 675px;
  }
}

.pageDiv {
  background-color: #728c9c;
  min-height: 100vh;
  background-image: linear-gradient(
    0deg,
    rgba(84, 105, 119, 1) 0%,
    rgba(180, 210, 230, 1) 100%
  );
}

.mobileNav {
  background-color: #485761;
  margin: 0;
  padding: 0;
  position: sticky;
  top: 0;
  z-index: 5;
}

.myBtn {
  background-color: #d88833;
  border: none;
  outline: none !important;
  text-align: center;
  border-radius: 8px;
  box-shadow: none !important;
  color: black !important;
  text-decoration: none;
  font-weight: normal;
  font-size: 20px;
  font-family: var(--bs-font-sans-serif);
}

.myBtn:hover:enabled {
  background-color: #c57928;
}

.myBtn:active {
  outline: none;
  background-color: #c57928;
}

.myBtn:disabled {
  background-color: rgba(240, 240, 240, 0.5);
  opacity: 0.7;
  cursor: not-allowed;
}

.linkBtn {
  height: fit-content;
  font-size: 1.25rem;
  vertical-align: middle;
  display: inline-block;
  margin: 0 20px;
  padding: 10px;
}

.mainNavbar {
  display: flex;
  justify-content: space-between;
  background-color: #485761;
  color: #d0893b !important;
  border-top: 1px solid #aaa1a1;
  font-weight: bold;
  font-size: 18px;
  margin: 0;
  padding: 0;
  position: sticky;
  top: 0px;
  z-index: 5;
}

.navContainer {
  color: #d0893b !important;
  margin: 0;
  padding-left: 40px;
  padding-right: 40px;
}

.navContainer:hover {
  background-color: #39454d;
}

.navDrop {
  background-color: #485761;
  color: #d0893b !important;
}

.navDrop:hover {
  background-color: #39454d;
}

.dropdown-toggle {
  color: #d0893b !important;
}

.dropdown-menu {
  background-color: #485761;
}

.contactFooter {
  background-color: #485761;
  color: white; /*font color*/
  font-weight: bold;
  border-top: 2px outset #44525c;
  padding: 5px;
  margin: 0 !important;
  text-align: center;
}

.homePage {
  padding: 0px 15px 0px 15px;
}

.aboutWrapper {
  display: absolute;
  background-image: url("./assets/chattanooga.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 25px 0;
}

.aboutDiv {
  display: flex;
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.7),
    rgba(255, 255, 255, 0.7)
  );
  margin: 0px 50px;
}

.productsDiv {
  display: flex;
  padding-bottom: 10px;
}

.productBtnsDiv {
  display: flex;
  justify-content: center;
}

#productsTitle {
  padding: 40px 20px 20px 20px;
  text-align: center;
}

.mainCards {
  background-color: inherit;
  border: none;
  color: black;
  margin: 25px;
  position: static;
  height: fit-content;
}

.productCards {
  background-color: inherit;
  border: none;
  color: black;
  margin: 0 25px 25px 25px;
  padding-top: 25px;
  position: static;
  height: fit-content;
  flex: 1;
}

.servicesDiv {
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0.5)
  );
  margin: 25px 50px 0 50px;
  padding-bottom: 30px;
}

.videoDiv {
  display: flex;
  justify-content: center;
}

.servicesCards {
  background-color: inherit;
  border: none;
  color: black;
  padding: 50px 50px 20px 50px;
  position: static;
  height: fit-content;
}

.sideInfo {
  color: black;
  margin: 100px 50px 50px 50px;
  padding: 5px;
  background-color: #728c9c;
  position: static;
  height: fit-content;
  font-size: 22px;
  font-family: Georgia, "Times New Roman", Times, serif;
}

.cardTitle {
  font-size: 36px;
  color: #000000;
  font-family: Georgia, "Times New Roman", Times, serif;
  font-style: italic;
  margin-bottom: 0;
  padding-bottom: 20px;
}

.mainBody {
  font-size: 24px;
  font-weight: 500;
  padding-top: 10px;
  margin-left: 20px;
  font-family: Georgia, "Times New Roman", Times, serif;
}

h1 {
  color: white;
  text-shadow: 2px 2px 2px black, 0 0 1em black, 0 0 0.2em black;
  font-family: "Arial Black", serif;
  font-weight: 600;
  font-style: italic;
  font-size: 75px;
}

.carousel-caption {
  text-align: right;
}

.Modal {
  position: absolute;
  top: 120px;
  left: 300px;
  right: 300px;
  bottom: 40px;
  border: 1px solid #9e9e9e;
  background: #e2e2e2;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  border-radius: 10px;
  outline: none;
  padding: 0;
  z-index: 200;
}

input[type="text"],
select,
textarea {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: white;
}

.modalHeader {
  background-color: #d0893b;
  margin: 0;
  font-family: var(--bs-font-sans-serif);
  padding: 20px;
  display: flex;
  justify-content: center;
}

label {
  margin: 10px 20px;
  width: 90%;
  font-family: var(--bs-font-sans-serif);
  font-size: 20px;
}

.transDiv {
  border: 2px solid #9e9e9e;
  border-radius: 8px;
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0.5)
  );
  margin: 2%;
  margin-top: 0;
  padding: 10px;
}

.radioDiv {
  background-color: #a5bfcf;
  border: 2px solid #9e9e9e;
  border-radius: 20px;
  margin-bottom: 30px;
}

.radio-wrap {
  margin-left: 30px;
  margin-bottom: 10px;
}

.quote-radio-label {
  display: inline-block;
  padding: 8px;
  margin: 0.5em;
  cursor: pointer;
  color: #000000;
  border-radius: 0.25em;
  background-color: #efefef;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2), inset 0 -3px 0 rgba(0, 0, 0, 0.22);
  transition: 0.3s;
  user-select: none;
  width: auto;
  &:hover {
    background: darken(#efefef, 10%);
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2),
      inset 0 -3px 0 rgba(0, 0, 0, 0.32);
  }
  &:active {
    transform: translateY(2px);
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2),
      inset 0px -1px 0 rgba(0, 0, 0, 0.22);
  }
}

.allRadio:checked + .quote-radio-label {
  background-color: #546977;
  color: white;
  &:hover {
    background: darken(#546977, 5%);
    color: darken(white, 5%);
  }
}

.color-radio-label {
  display: inline-block;
  padding: 8px;
  margin: 0.5em;
  cursor: pointer;
  color: #000000;
  border-radius: 0.25em;
  background-color: #efefef;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2), inset 0 -3px 0 rgba(0, 0, 0, 0.22);
  transition: 0.3s;
  user-select: none;
  width: auto;
  &:hover {
    background: darken(#efefef, 10%);
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2),
      inset 0 -3px 0 rgba(0, 0, 0, 0.32);
  }
  &:active {
    transform: translateY(2px);
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2),
      inset 0px -1px 0 rgba(0, 0, 0, 0.22);
  }
  &:first-letter {
    text-transform: uppercase;
  }
}

.allRadio:checked + .color-radio-label {
  border: 5px solid royalblue;
}

.quantityField {
  width: 100%;
  padding: 12px 20px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

#totalDiv {
  width: auto;
  display: inline-block;
  background-color: #f5f5f5;
  border: 2px solid #9e9e9e;
  border-radius: 8px;
  margin-bottom: 30px;
  padding: 10px;
}

.innerRadioDiv {
  padding-left: 30px;
}

.slider {
  max-width: 800px;
  margin: 30px;
}

.slider-track {
  /* Top value to align your track to the center of your thumb */
  top: 8px;
  /* thickness of the track */
  height: 10px;
  /* rounded edges */
  border-radius: 8px;
  /* default color of your track */
  background: rgb(104, 117, 217);
}

.slider-track.slider-track-0 {
  /* color of the track before the thumb */
  background: #ddd;
}

.slider-thumb {
  cursor: pointer;
  /*color for the thumb */
  background: rgb(104, 117, 217);
  /* shape of the thumb: circle */
  width: 30px;
  height: 30px;
  border-radius: 100%;
  /* remove default outline when selected */
  outline: none;
}

.slider-thumb:hover {
  box-shadow: 0 0 0 8px rgb(104, 117, 217, 0.2);
}

.slider-mark {
  display: none;
}

.markup-footer {
  margin-left: 60px;
  padding-bottom: 10px;
}
.markup-label {
  font-size: 16px;
  margin: none;
  position: relative;
  left: -40px;
  text-align: center;
  background: #39454d;
  color: white;
  width: fit-content;
  padding: 5px;
  border-radius: 8px;
}

.infoBtn {
  border: none;
  background: #f5f5f5;
  float: right;
  margin-left: 15px;
}

[role="tooltip"].popup-content {
  width: fit-content;
  max-width: 300px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}
